export const General = {
  title: {
    fontFamily: 'Work Sans',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '28px',
    marginTop: 0,
    marginBottom: 12,
  },
  item: {
    borderBottomColor: '#000',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    marginBottom: 0,
  },
  info: {
    paddingVertical: 22,
    paddingHorizontal: 16,
  },
  meta: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 17,
  },
  metaText: {
    fontFamily: 'Work Sans',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 0.5,
  },
  metaDate: {
    fontWeight: 400,
    letterSpacing: 0,
  },
  image: {
    width: '100%',
    maxWidth: 500,
    height: 470,
    resizeMode: 'cover',
  },
  author: {
    marginBottom: 0,
  },
  authorName: {
    fontWeight: 700,
  },
  authorText: {
    fontFamily: 'Work Sans',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 16,
  },
  seeMore: {
    fontFamily: 'Work Sans',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 0.5,
    color: '#FF8DD4',
    borderBottomColor: '#FF8DD4',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  seeMoreInset: {
    marginTop: 22,
    marginRight: 16,
  },
  seeMoreBlack: {
    color: '#000',
    borderBottomColor: '#000',
  },
  alignRight: {
    display: 'flex',
    alignItems: 'flex-end',
  },
};

export const Section = {
  titleSection: {
    width: '100%',
    height: 60,
    resizeMode: 'contain',
    marginBottom: 14,
  },
  textSectionTitle: {
    fontFamily: 'Work Sans',
    fontSize: 30,
    lineHeight: '39px',
    fontWeight: 600,
    margin: 0,
    padding: 16,
  },
  sectionTitle: {
    marginVertical: 14,
  },
  sectionTwoColumns: {
    display: 'flex',
    borderTopColor: '#000',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  halfColumn: {
    display: 'flex',
    width: '50%',
    padding: 16,
    boxSizing: 'border-box',
    flexDirection: 'column',
    rowGap: 12,
  },
  halfColumnLeft: {
    borderRightColor: '#000',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
  },
  halfColumnImage: {
    width: '100%',
    height: 195,
    resizeMode: 'cover',
  },
  halfRoundedColumnImage: {
    width: '100%',
    height: 300,
    resizeMode: 'cover',
    borderRadius: 16,
    overflow: 'hidden',
  },
  halfColumnTitle: {
    fontFamily: 'Work Sans',
    fontSize: 16,
    lineHeight: '18px',
    fontWeight: 600,
    margin: 0,
  },
  halfColumnAuthorName: {
    fontWeight: 700,
    letterSpacing: 0,
    marginBottom: 0,
  },
  halfColumnMeta: {
    marginBottom: 0,
  },
  halfColumnMetaText: {
    fontFamily: 'Work Sans',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: 12,
    letterSpacing: 0.4,
    marginBottom: 3,
  },
  halfColumnMetaDate: {
    fontWeight: 400,
    letterSpacing: 0,
    marginBottom: 0,
  },
  horizontalList: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    overflowY: 'auto',
    columnGap: 12,
    paddingHorizontal: 16,
  },
  horizontalColumn: {
    width: 180,
    padding: 12,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#000',
    borderStyle: 'solid',
    flexShrink: 0,
    rowGap: 9,
  },
  horizontalRoundedColumn: {
    width: 192,
    borderRadius: 16,
  },
  focusSection: {
    borderTopWidth: 1,
    borderTopColor: '#000',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  focusSectionItem: {
    border: 0,
  },
  focusSectionInfo: {
    paddingHorizontal: 0,
  },
  focusSectionImage: {
    height: 420,
  },
  editorsReviewFocusSection: {
    paddingTop: 22,
    borderBottomWidth: 0,
  },
  nowTrendingFocusSection: {
    paddingTop: 22,
    borderBottomWidth: 0,
  },
  compactList: {
    margin: 0,
  },
  compactListTitle: {
    fontSize: 16,
    lineHeight: '18px',
  },
  compactListDetail: {
    display: 'block',
  },
  compactListImage: {
    width: 64,
    height: 80,
    resizeMode: 'cover',
  },
  compactListItem: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 12,
    flexWrap: 'nowrap',
    borderTopColor: '#000',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    padding: 16,
  },
  compactListItemNoBorderTop: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 12,
    flexWrap: 'nowrap',
    borderTopColor: 'transparent',
    borderTopWidth: 0,
    borderTopStyle: 'none',
    padding: 16,
  },
  compactListMeta: {
    marginBottom: 12,
  },
  compactListMetaText: {
    fontFamily: 'Work Sans',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: 12,
    letterSpacing: 0.42,
    marginBottom: 3,
  },
  compactListMetaDate: {
    fontWeight: 400,
    letterSpacing: 0,
    marginBottom: 0,
  },
  compactListAuthorText: {
    fontSize: 10,
    lineHeight: 12,
  },
  singleArticle: {
    width: 'auto',
    height: 595,
    borderColor: '#000',
    borderWidth: 1,
    marginHorizontal: 16,
    paddingHorizontal: 12,
    paddingTop: 14,
    paddingBottom: 20,
    backgroundColor: '#fff',
  },
  singleArticleImage: {
    width: 'auto',
    height: 397,
    marginBottom: 20,
  },
  borderTop: {
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: '#000',
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#000',
  },
};

export const Button = {
  buttonsArea: {
    marginBottom: 22,
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    columnGap: 15,
  },
  buttonText: {
    fontFamily: 'Work Sans',
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 0.2,
    fontWeight: 600,
    paddingVertical: 10,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000',
    borderRadius: 18,
  },
  buttonTextSelected: {
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: 'transparent',
    backgroundColor: '#FF8DD4',
  },
  TrendingTags: {
    borderWidth: 0,
    padding: 16,
    marginTop: 0,
    marginBottom: 0,
  },
};

export const Slides = {
  slidesSection: {
    paddingHorizontal: 16,
    marginBottom: 6,
  },
  slidesItem: {
    paddingRight: 70,
  },
  slidesSectionItem: {
    border: 0,
  },
  slidesSectionTitle: {
    fontSize: 15,
    lineHeight: '20px',
    marginBottom: 10,
  },
  slidesSectionMeta: {
    marginBottom: 9,
  },
  slidesSectionMetaText: {
    fontSize: 10,
    lineHeight: 12,
    letterSpacing: 0.4,
  },
  slidesSectionInfo: {
    paddingTop: 20,
    paddingBottom: 6,
    paddingHorizontal: 0,
  },
  slidesSectionAuthorText: {
    fontSize: 10,
    lineHeight: 12,
  },
};

export const ScreenLayout = {
  flex: {
    flex: 1,
  },
  flexNum: (num) => ({
    flex: num,
  }),
  flexCenter: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexBetween: {
    justifyContent: 'space-between',
  },
  direction: (flow) => ({
    flexDirection: flow,
  }),
  directionFlex: (flow) => ({
    flex: 1,
    flexDirection: flow,
  }),
  flexWrapRowGap: (gap) => ({
    flex: 1,
    flexWrap: 'wrap',
    rowGap: gap,
  }),
  centerRowGap: (gap) => ({
    alignItems: 'center',
    rowGap: gap,
  }),
  centerColGap: (gap) => ({
    alignItems: 'center',
    columnGap: gap,
  }),
  flexWhite: {
    backgroundColor: '#FFFFFF',
    flex: 1,
  },
  displayFlex: {
    display: 'flex',
  },
};
