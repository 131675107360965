import { createSlice } from '@reduxjs/toolkit';
import {
  getHighlightHome,
  getTrending,
  getBeautyAgenda,
  getBeautyShort,
  getPostData,
  getTheHighlight,
  getInfiniteBottomList,
  getSectionData,
  getHotPickTags,
  getGlossary,
  getHotPickData,
  getPinnedHighlight,
  getNowTrendingData,
  getQuizListData,
  getTrendingReview,
  getGlossaryTrending,
  getFreshPick,
  getWordOfTheDay,
  getBeautyBottomList,
  getGlossaryBanner,
  getGlossaryList,
  getPostGlossary,
  getInfiniteBottomListCount,
  getBeautyShortCount,
  getBeautyBottomListCount,
} from './sectionThunk';
import { removeDuplicateArray } from '@web-utils';

const initialState = {
  status: {
    getHighlightHomeFetch: false,
    getHighlightHomeError: null,
    getTrendingFetch: false,
    getTrendingError: null,
    getBeautyAgendaFetch: false,
    getBeautyAgendaError: null,
    getBeautyShortFetch: false,
    getBeautyShortError: null,
    getPostDataFetch: false,
    getPostDataError: null,
    getTheHighlightFetch: false,
    getTheHighlightError: null,
    getInfiniteListFetch: false,
    getInfiniteListError: null,
    getSectionFetch: false,
    getSectionError: null,
    getHotPickTagFetch: false,
    getHotPickTagError: null,
    getHotPickDataFetch: false,
    getHotPickDataError: null,
    getGlossaryFetch: false,
    getGlossaryError: null,
    getPinnedHighlightFetch: false,
    getPinnedHighlightError: null,
    getNowTrendingDataFetch: false,
    getNowTrendingDataError: null,
    getQuizListDataFetch: false,
    getQuizListDataError: null,
    getTrendingReviewFetch: false,
    getTrendingReviewError: null,
    getGlossaryTrendingFetch: false,
    getGlossaryTrendingError: null,
    getFreshPickFetch: false,
    getFreshPickError: null,
    getWordOfTheDayFetch: false,
    getWordOfTheDayError: null,
    getBeautyBottomListFetch: false,
    getBeautyBottomListError: null,
    getGlossaryBannerFetch: false,
    getGlossaryBannerError: null,
    getGlossaryListFetch: false,
    getGlossaryListError: null,
    getPostGlossaryFetch: false,
    getPostGlossaryError: null,
    getBeautyBottomListCountFetch: false,
    getBeautyBottomListCountError: null,
  },
  highlightData: [],
  trendingData: [],
  beautyAgendaData: [],
  captionData: [],
  beautyShortData: [],
  postData: null,
  theHighlightData: [],
  editorReviewData: [],
  infiniteListData: [],
  infiniteListCount: 0,
  lastInfiniteListPostSortId: null,
  guideData: [],
  hotPickTag: [],
  glossaryData: [],
  hotPickData: [],
  indexListArticleSection: 0,
  pinnedHighlight: {
    whatsNewResult: [],
    whatsGoodResult: [],
    whatsFunResult: [],
  },
  newsScoopData: [],
  whoToWatchData: [],
  askTheExpertsData: [],
  eventData: [],
  nowTrendingData: [],
  nowTrendingTag: [],
  indexNowTrendingSection: 0,
  hotInspoData: [],
  theAListData: [],
  topPickTag: [],
  topPickData: [],
  indexTopPicksSection: 0,
  quizListData: {
    mustTryQuizData: [],
    randomQuizData: [],
  },
  urlFindsData: [],
  trendingFindsData: [],
  trendingReviewData: [],
  glossaryTrendingData: [],
  freshPickData: [],
  wordOfTheDayData: [],
  beautyBottomListData: [],
  lastBeautyInfiniteListPostSortId: null,
  glossaryBannerData: [],
  glossaryListData: {
    allGlossaryListData: [],
    singleGlossaryListData: [],
    detailGlossaryData: null,
    singleGlossaryListCount: 0,
  },
  shortListData: [],
  beautyShortDataCount: 0,
  beautyBottomListCount: 0,
};

const { reducer, actions } = createSlice({
  name: 'section',
  initialState,
  reducers: {
    setCaption(state, action) {
      state.captionData = action.payload;
    },
    resetPostData(state) {
      state.postData = null;
    },
    resetShortData(state) {
      state.beautyShortData = [];
    },
    resetInfiniteListData(state) {
      state.infiniteListData = [];
    },
    resetBeautyBottomListData(state) {
      state.beautyBottomListData = [];
    },
    resetGlossaryDetailData(state) {
      state.glossaryListData.detailGlossaryData = null;
    },
    setActiveIndexListArticle(state, action) {
      state.indexListArticleSection = action.payload;
    },
    setActiveIndexNowTrending(state, action) {
      state.indexNowTrendingSection = action.payload;
    },
    setActiveIndexTopPicks(state, action) {
      state.indexTopPicksSection = action.payload;
    },
    setShortListData(state, action) {
      const initialId = action.payload._id;

      const restData = state.beautyShortData.filter((e) => e._id !== initialId);
      const updatedData = restData.map((item) => {
        return {
          _id: item._id,
          slug: item.slug,
          title: item.title,
          category: item.category,
          published_at: item.published_at,
        };
      });
      state.shortListData = [action.payload, ...updatedData];
    },
    resetShorListData(state) {
      state.shortListData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHighlightHome.pending, (state) => {
        state.status.getHighlightHomeError = null;
        state.status.getHighlightHomeFetch = true;
      })
      .addCase(getHighlightHome.rejected, (state, action) => {
        state.status.getHighlightHomeFetch = false;
        state.status.getHighlightHomeError = action?.error?.message;
      })
      .addCase(getHighlightHome.fulfilled, (state, { payload }) => {
        state.status.getHighlightHomeFetch = false;
        state.highlightData = payload.data;
      })
      .addCase(getTrending.pending, (state) => {
        state.status.getTrendingError = null;
        state.status.getTrendingFetch = true;
      })
      .addCase(getTrending.rejected, (state, action) => {
        state.status.getTrendingFetch = false;
        state.status.getTrendingError = action?.error?.message;
      })
      .addCase(getTrending.fulfilled, (state, { payload }) => {
        state.status.getTrendingFetch = false;
        state.trendingData = payload.data;
      })
      .addCase(getBeautyAgenda.pending, (state) => {
        state.status.getBeautyAgendaError = null;
        state.status.getBeautyAgendaFetch = true;
      })
      .addCase(getBeautyAgenda.rejected, (state, action) => {
        state.status.getBeautyAgendaFetch = false;
        state.status.getBeautyAgendaError = action?.error?.message;
      })
      .addCase(getBeautyAgenda.fulfilled, (state, { payload }) => {
        state.status.getBeautyAgendaFetch = false;
        state.beautyAgendaData = payload.data;
      })
      .addCase(getBeautyShort.pending, (state) => {
        state.status.getBeautyShortError = null;
        state.status.getBeautyShortFetch = true;
      })
      .addCase(getBeautyShort.rejected, (state, action) => {
        state.status.getBeautyShortFetch = false;
        state.status.getBeautyShortError = action?.error?.message;
      })
      .addCase(getBeautyShort.fulfilled, (state, { payload }) => {
        state.status.getBeautyShortFetch = false;
        state.beautyShortData = removeDuplicateArray(
          state.beautyShortData.concat(payload.data),
          '_id',
        );
        const newShorts = payload.data.map((item) => ({
          _id: item._id,
          slug: item.slug,
          title: item.title,
          category: item.category,
          published_at: item.published_at,
        }));
        state.shortListData = removeDuplicateArray(
          state.shortListData.concat(newShorts),
          '_id',
        );
      })
      .addCase(getPostData.pending, (state) => {
        state.status.getPostDataError = null;
        state.status.getPostDataFetch = true;
      })
      .addCase(getPostData.rejected, (state, action) => {
        state.status.getPostDataFetch = false;
        state.status.getPostDataError = action?.error?.message;
      })
      .addCase(getPostData.fulfilled, (state, { payload }) => {
        state.status.getPostDataFetch = false;
        if (payload.type === 'update') {
          state.shortListData = state.shortListData.map((item) => {
            if (item._id === payload.id) {
              return {
                ...payload.data.data[0],
                _id: item._id,
              };
            }
            return item;
          });
          state.postData = payload.data.data[0];
        } else {
          state.postData = payload.data.data[0];
        }
      })
      .addCase(getTheHighlight.pending, (state) => {
        state.status.getTheHighlightError = null;
        state.status.getTheHighlightFetch = true;
      })
      .addCase(getTheHighlight.rejected, (state, action) => {
        state.status.getTheHighlightFetch = false;
        state.status.getTheHighlightError = action?.error?.message;
      })
      .addCase(getTheHighlight.fulfilled, (state, { payload }) => {
        state.status.getTheHighlightFetch = false;
        state.theHighlightData = payload.data;
      })
      .addCase(getInfiniteBottomList.pending, (state) => {
        state.status.getInfiniteListError = null;
        state.status.getInfiniteListFetch = true;
      })
      .addCase(getInfiniteBottomList.rejected, (state, action) => {
        state.status.getInfiniteListFetch = false;
        state.status.getInfiniteListError = action?.error?.message;
      })
      .addCase(getInfiniteBottomList.fulfilled, (state, { payload }) => {
        state.status.getInfiniteListFetch = false;
        state.lastInfiniteListPostSortId = payload.data.lastResultSortId;
        state.infiniteListData = removeDuplicateArray(
          state.infiniteListData.concat(payload.data.posts),
          '_id',
        );
      })
      .addCase(getSectionData.pending, (state) => {
        state.status.getSectionFetch = true;
        state.status.getSectionError = null;
      })
      .addCase(getSectionData.rejected, (state, action) => {
        state.status.getSectionFetch = false;
        state.status.getSectionError = action?.error?.message;
      })
      .addCase(getSectionData.fulfilled, (state, { payload }) => {
        const updateData = (state, payload) => {
          const locationMap = {
            'editors-review': 'editorReviewData',
            guides: 'guideData',
            'news-scoop': 'newsScoopData',
            'who-to-watch': 'whoToWatchData',
            'ask-the-experts': 'askTheExpertsData',
            event: 'eventData',
            'hot-inspo': 'hotInspoData',
            'the-a-list': 'theAListData',
            'url-finds': 'urlFindsData',
            'trending-finds': 'trendingFindsData',
          };
          const locationType = locationMap[payload.type];
          state[locationType] = payload.data.data;
        };
        updateData(state, payload);
        state.status.getSectionFetch = false;
      })
      .addCase(getHotPickTags.pending, (state) => {
        state.status.getHotPickTagFetch = true;
        state.status.getHotPickTagError = null;
      })
      .addCase(getHotPickTags.rejected, (state, action) => {
        state.status.getHotPickTagFetch = false;
        state.status.getHotPickTagError = action?.error?.message;
      })
      .addCase(getHotPickTags.fulfilled, (state, { payload }) => {
        const updateData = (state, payload) => {
          const locationMap = {
            'hot-picks': 'hotPickTag',
            'now-trending': 'nowTrendingTag',
            'top-picks': 'topPickTag',
          };
          const locationType = locationMap[payload.type];
          state[locationType] = payload.data.data;
        };
        updateData(state, payload);
        state.status.getHotPickTagFetch = false;
      })
      .addCase(getGlossary.pending, (state) => {
        state.status.getGlossaryFetch = true;
        state.status.getGlossaryError = null;
      })
      .addCase(getGlossary.rejected, (state, action) => {
        state.status.getGlossaryFetch = false;
        state.status.getGlossaryError = action?.error?.message;
      })
      .addCase(getGlossary.fulfilled, (state, { payload }) => {
        state.status.getGlossaryFetch = false;
        state.glossaryData = payload.data.posts;
      })
      .addCase(getHotPickData.pending, (state) => {
        state.status.getHotPickDataFetch = true;
        state.status.getHotPickDataError = null;
      })
      .addCase(getHotPickData.rejected, (state, action) => {
        state.status.getHotPickDataFetch = false;
        state.status.getHotPickDataError = action?.error?.message;
      })
      .addCase(getHotPickData.fulfilled, (state, { payload }) => {
        state.status.getHotPickDataFetch = false;
        state.hotPickData = payload.data;
      })
      .addCase(getPinnedHighlight.pending, (state) => {
        state.status.getPinnedHighlightFetch = true;
        state.status.getPinnedHighlightError = null;
      })
      .addCase(getPinnedHighlight.rejected, (state, action) => {
        state.status.getPinnedHighlightFetch = false;
        state.status.getPinnedHighlightError = action?.error?.message;
      })
      .addCase(getPinnedHighlight.fulfilled, (state, { payload }) => {
        const updateData = (state, payload) => {
          const locationMap = {
            'whats-new': 'whatsNewResult',
            'whats-good': 'whatsGoodResult',
            'whats-fun': 'whatsFunResult',
          };
          const locationType = locationMap[payload.type];
          state.pinnedHighlight[locationType] = payload.data.data;
        };
        updateData(state, payload);
        state.status.getPinnedHighlightFetch = false;
      })
      .addCase(getNowTrendingData.pending, (state) => {
        state.status.getNowTrendingDataFetch = true;
        state.status.getNowTrendingDataError = null;
      })
      .addCase(getNowTrendingData.rejected, (state, action) => {
        state.status.getNowTrendingDataFetch = false;
        state.status.getNowTrendingDataError = action?.error?.message;
      })
      .addCase(getNowTrendingData.fulfilled, (state, { payload }) => {
        const updateData = (state, payload) => {
          const locationMap = {
            'now-trending': 'nowTrendingData',
            'top-picks': 'topPickData',
          };
          const locationType = locationMap[payload.type];
          state[locationType] = payload.data.data;
        };
        updateData(state, payload);
        state.status.getNowTrendingDataFetch = false;
      })
      .addCase(getQuizListData.pending, (state) => {
        state.status.getQuizListDataFetch = true;
        state.status.getQuizListDataError = null;
      })
      .addCase(getQuizListData.rejected, (state, action) => {
        state.status.getQuizListDataFetch = false;
        state.status.getQuizListDataError = action?.error?.message;
      })
      .addCase(getQuizListData.fulfilled, (state, { payload }) => {
        const updateData = (state, payload) => {
          const locationMap = {
            random: 'randomQuizData',
            'must-try': 'mustTryQuizData',
          };
          const locationType = locationMap[payload.type];
          state.quizListData[locationType] = payload.data.data;
        };
        updateData(state, payload);
        state.status.getQuizListDataFetch = false;
      })
      .addCase(getTrendingReview.pending, (state) => {
        state.status.getTrendingReviewFetch = true;
        state.status.getTrendingReviewError = null;
      })
      .addCase(getTrendingReview.rejected, (state, action) => {
        state.status.getTrendingReviewFetch = false;
        state.status.getTrendingReviewError = action?.error?.message;
      })
      .addCase(getTrendingReview.fulfilled, (state, { payload }) => {
        state.status.getTrendingReviewFetch = false;
        state.trendingReviewData = payload.data;
      })
      .addCase(getGlossaryTrending.pending, (state) => {
        state.status.getGlossaryTrendingFetch = true;
        state.status.getGlossaryTrendingError = null;
      })
      .addCase(getGlossaryTrending.rejected, (state, action) => {
        state.status.getGlossaryTrendingFetch = false;
        state.status.getGlossaryTrendingError = action?.error?.message;
      })
      .addCase(getGlossaryTrending.fulfilled, (state, { payload }) => {
        state.status.getGlossaryTrendingFetch = false;
        state.glossaryTrendingData = payload.data;
      })
      .addCase(getFreshPick.pending, (state) => {
        state.status.getFreshPickFetch = true;
        state.status.getFreshPickError = null;
      })
      .addCase(getFreshPick.rejected, (state, action) => {
        state.status.getFreshPickFetch = false;
        state.status.getFreshPickError = action?.error?.message;
      })
      .addCase(getFreshPick.fulfilled, (state, { payload }) => {
        state.status.getFreshPickFetch = false;
        state.freshPickData = payload.data;
      })
      .addCase(getWordOfTheDay.pending, (state) => {
        state.status.getWordOfTheDayFetch = true;
        state.status.getWordOfTheDayError = null;
      })
      .addCase(getWordOfTheDay.rejected, (state, action) => {
        state.status.getWordOfTheDayFetch = false;
        state.status.getWordOfTheDayError = action?.error?.message;
      })
      .addCase(getWordOfTheDay.fulfilled, (state, { payload }) => {
        state.status.getWordOfTheDayFetch = false;
        state.wordOfTheDayData = payload.data;
      })
      .addCase(getBeautyBottomList.pending, (state) => {
        state.status.getBeautyBottomListError = null;
        state.status.getBeautyBottomListFetch = true;
      })
      .addCase(getBeautyBottomList.rejected, (state, action) => {
        state.status.getBeautyBottomListFetch = false;
        state.status.getBeautyBottomListError = action?.error?.message;
      })
      .addCase(getBeautyBottomList.fulfilled, (state, { payload }) => {
        state.status.getBeautyBottomListFetch = false;
        state.lastBeautyInfiniteListPostSortId = payload.data.lastResultSortId;
        state.beautyBottomListData = removeDuplicateArray(
          state.beautyBottomListData.concat(payload.data.posts),
          '_id',
        );
      })
      .addCase(getGlossaryBanner.fulfilled, (state, { payload }) => {
        state.status.getGlossaryBannerFetch = false;
        state.glossaryBannerData = payload.data;
      })
      .addCase(getGlossaryList.pending, (state) => {
        state.status.getGlossaryListFetch = true;
        state.status.getGlossaryListError = null;
      })
      .addCase(getGlossaryList.rejected, (state, action) => {
        state.status.getGlossaryListFetch = false;
        state.status.getGlossaryListError = action?.error?.message;
      })
      .addCase(getGlossaryList.fulfilled, (state, { payload }) => {
        const updateData = (state, payload) => {
          const locationMap = {
            all: 'allGlossaryListData',
            single: 'singleGlossaryListData',
            detail: 'detailGlossaryData',
            count: 'singleGlossaryListCount',
          };
          const locationType = locationMap[payload.type];

          if (payload.skip !== undefined) {
            state.glossaryListData[locationType][0].data = removeDuplicateArray(
              state.glossaryListData[locationType][0].data.concat(
                payload.data[0].data,
              ),
              '_id',
            );
          } else if (payload.count) {
            state.glossaryListData[locationType] = payload.count;
          } else {
            state.glossaryListData[locationType] = payload.data;
          }
        };
        updateData(state, payload);
        state.status.getGlossaryListFetch = false;
      })
      .addCase(getPostGlossary.pending, (state) => {
        state.status.getPostGlossaryFetch = true;
        state.status.getPostGlossaryError = null;
      })
      .addCase(getPostGlossary.rejected, (state, action) => {
        state.status.getPostGlossaryFetch = false;
        state.status.getPostGlossaryError = action?.error?.message;
      })
      .addCase(getPostGlossary.fulfilled, (state, { payload }) => {
        state.status.getPostGlossaryFetch = false;
        state.glossaryListData.detailGlossaryData = payload.data;
      })
      .addCase(getInfiniteBottomListCount.fulfilled, (state, { payload }) => {
        state.infiniteListCount = payload;
      })
      .addCase(getBeautyShortCount.fulfilled, (state, { payload }) => {
        state.beautyShortDataCount = payload.count;
      })
      .addCase(getBeautyBottomListCount.pending, (state) => {
        state.status.getBeautyBottomListCountFetch = true;
        state.status.getBeautyBottomListCountError = null;
      })
      .addCase(getBeautyBottomListCount.rejected, (state, action) => {
        state.status.getBeautyBottomListCountFetch = false;
        state.status.getBeautyBottomListCountError = action?.error?.message;
      })
      .addCase(getBeautyBottomListCount.fulfilled, (state, { payload }) => {
        state.status.getBeautyBottomListCountFetch = false;
        state.beautyBottomListCount = payload;
      });
  },
});

export const {
  setCaption,
  resetPostData,
  resetShortData,
  resetInfiniteListData,
  setActiveIndexListArticle,
  setActiveIndexNowTrending,
  setActiveIndexTopPicks,
  resetBeautyBottomListData,
  resetGlossaryDetailData,
  setShortListData,
  resetShorListData,
} = actions;
export const selectSectionFetchStatus = (type) => {
  return (state) => state.section.status[type];
};
export const selectSectionState = (type) => {
  return (state) => state.section[type];
};

export default reducer;
