export const BLACK = '#000000';
export const WHITE = '#ffffff';
export const CARNATION_PINK = '#FF8DD4';
export const AMARANTH_PINK = '#F09AB9';
export const LIGHT_SILVER = '#D8D8D8';
export const SPANISH_GRAY = '#999999';
export const PLATINUM = '#E5E5E5';
export const QUICK_SILVER = '#A0A0A0';
export const CULTURED = '#F7F7F7';
export const ICTERINE = '#F2F962';
export const SUNNY = '#F5FA81';
export const PASTEL_YELLOW = '#F7FB9B';
export const SPRING_BUD = '#A1F916';
export const BRIGHT_TURQUOISE = '#18EAC2';
export const BLAZE_ORANGE = '#FF6C08';
export const BOSTON_RED = '#C80000';
export const AMARANTH_PURPLE = '#B22655';
export const VENETIAN_RED = '#DA2A52';
export const CADET_GREY = '#98A2B3';
export const CHARCOAL = '#8C8582';
export const PINK = '#F384A7';
export const SOCO_STAR_BORDER = '#B22254';
export const SOCO_STAR_BG = '#FA9DC5';
export const RISING_STAR_BORDER = '#B22254';
export const RISING_STAR_BG = '#F45990';
export const SUPER_STAR_BORDER = '#B22254';
export const SUPER_STAR_BG = '#AE85EA';
export const DIVA_STAR_BORDER = '#B22254';
export const DIVA_STAR_BG = '#EA6EEA';
export const KURO_GREEN = '#1C2A1B';
export const VIBRANT_ORANGE = '#E87722';
