import React, {
  Suspense,
  useState,
  useRef,
  useEffect,
  lazy,
  useCallback,
} from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';
import { PortalProvider } from '@gorhom/portal';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import relativeTime from 'dayjs/plugin/relativeTime';
import axios from 'axios';
import { loadAsync } from 'expo-font';
import { HelmetProvider } from 'react-helmet-async';

import { store } from '~store/index';
import interceptorsSetup from './global/interceptors';
import RootNavigator, { config } from '@web/navigation/RootNavigator';

import { Fonts } from './assets/fonts';

const Config = require('./config/default.env').default;
const LoaderOverlay = lazy(() =>
  import('~global-components/loaderOverlay/LoaderOverlay'),
);

const App = () => {
  const [appReady, setAppReady] = useState(false);
  const [routeActive, setRouteActive] = useState('');
  const navigationRef = useRef();
  const baseLoading = document.getElementById('baseLoading');

  const cacheFonts = (fonts) => {
    return fonts.map((font) => loadAsync(font));
  };

  const fetchAssets = useCallback(async () => {
    try {
      axios.defaults.withCredentials = true;
      await axios.get(`${Config.MS_SSO_BROKER_API_URL}`);
      if (baseLoading) {
        baseLoading.remove();
      }
      const fontAssets = cacheFonts(Fonts);
      await Promise.all([...fontAssets]);
    } catch (e) {
      console.warn(e);
    } finally {
      setAppReady(true);
    }
  }, []);

  useEffect(() => {
    const initializeApp = async () => {
      await fetchAssets();
      dayjs.locale('id');
      dayjs.extend(relativeTime);
      interceptorsSetup();
    };

    initializeApp();
  }, [fetchAssets]);

  if (!appReady) {
    return <LoaderOverlay show={!appReady} full />;
  }

  return (
    <>
      <Provider store={store}>
        <PortalProvider>
          <HelmetProvider>
            <SafeAreaProvider>
              <Suspense fallback={<></>}>
                <NavigationContainer
                  ref={navigationRef}
                  linking={{
                    ...config,
                  }}
                  onReady={() => {
                    const currentRouteName =
                      navigationRef.current.getCurrentRoute()?.name;
                    setRouteActive(currentRouteName);
                  }}
                  onStateChange={() => {
                    const currentRouteName =
                      navigationRef.current.getCurrentRoute()?.name;
                    setRouteActive(currentRouteName);
                  }}>
                  <RootNavigator routeActive={routeActive} />
                </NavigationContainer>
              </Suspense>
            </SafeAreaProvider>
          </HelmetProvider>
        </PortalProvider>
      </Provider>
    </>
  );
};

export default App;
